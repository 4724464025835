<template>
  <div
    class="
      min-h-screen
      flex
      items-center
      justify-center
      bg-gray-50
      py-12
      px-4
      sm:px-6
      lg:px-8
    "
  >
    <div
      :class="isLoading ? 'hidden' : 'block'"
      class="max-w-md w-full space-y-8"
    >
      <div>
        <router-link to="/" class="">
        <img
          class="mx-auto h-12 w-auto"
          src="../../assets/mystidia.png"
          alt="Mystidia"
        />
        </router-link>
        <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">
          Créer un compte
        </h2>
      </div>
      <form class="mt-8 space-y-6" @submit.prevent="register">
        <input type="hidden" name="remember" value="true" />
        <div class="rounded-md shadow-sm -space-y-px">
          <div class="flex flex-row justify-center items-center">
            <label for="last-name" class="sr-only">Nom</label>
            <input
              v-model="form.lastname"
              id="last-name"
              name="last-name"
              type="text"
              autocomplete="last-name"
              required="required"
              class="
                appearance-none
                rounded-none
                relative
                block
                w-full
                px-3
                py-3
                border border-gray-300
                placeholder-gray-500
                text-gray-900
                rounded-tl-md
                focus:outline-none
                focus:ring-red-400
                focus:border-red-400
                focus:z-10
                sm:text-sm
              "
              placeholder="Nom de famille"
            />

            <label for="first-name" class="sr-only">Prénom</label>
            <input
              v-model="form.firstname"
              id="first-name"
              name="first-name"
              type="text"
              autocomplete="irst-name"
              required="required"
              class="
                appearance-none
                rounded-none
                relative
                block
                w-full
                px-3
                py-3
                border border-gray-300
                placeholder-gray-500
                text-gray-900
                rounded-tr-md
                focus:outline-none
                focus:ring-red-400
                focus:border-red-400
                focus:z-10
                sm:text-sm
              "
              placeholder="Prénom"
            />
          </div>

          <div>
            <label for="email-address" class="sr-only">Adresse Email</label>
            <input
              v-model="form.email"
              id="email-address"
              name="email"
              type="email"
              autocomplete="email"
              required="required"
              class="
                appearance-none
                rounded-none
                relative
                block
                w-full
                px-3
                py-3
                border border-gray-300
                placeholder-gray-500
                text-gray-900
                focus:outline-none
                focus:ring-red-400
                focus:border-red-400
                focus:z-10
                sm:text-sm
              "
              placeholder="Adresse Email"
            />
          </div>
          <div>
            <label for="password" class="sr-only">Mot de passe</label>
            <input
              v-model="form.password"
              id="password"
              name="password"
              type="password"
              autocomplete="current-password"
              required="required"
              class="
                appearance-none
                rounded-none
                relative
                block
                w-full
                px-3
                py-3
                border border-gray-300
                placeholder-gray-500
                text-gray-900
                focus:outline-none
                focus:ring-red-400
                focus:border-red-400
                focus:z-10
                sm:text-sm
              "
              placeholder="Mot de passe"
            />
          </div>
          <div>
            <label for="password" class="sr-only"
              >Réintroduire le mot de passe</label
            >
            <input
              v-model="form.password_confirmation"
              id="password_confirmation"
              name="password_confirmation"
              type="password"
              autocomplete="current-password"
              required="required"
              class="
                appearance-none
                rounded-none
                relative
                block
                w-full
                px-3
                py-3
                border border-gray-300
                placeholder-gray-500
                text-gray-900
                rounded-b-md
                focus:outline-none
                focus:ring-red-400
                focus:border-red-400
                focus:z-10
                sm:text-sm
              "
              placeholder="Retapez le mot de passe"
            />
          </div>
        </div>

        <div>
          <button
            type="submit"
            class="
              group
              relative
              w-full
              flex
              justify-center
              py-3
              px-4
              border border-transparent
              text-sm
              font-medium
              rounded-md
              text-white
              bg-red-400
              hover:bg-red-500
              focus:outline-none
              focus:ring-2 focus:ring-offset-2 focus:ring-red-400
            "
          >
            <span class="absolute left-0 inset-y-0 flex items-center pl-3">
              <!-- Heroicon name: solid/lock-closed -->
              <svg
                class="h-5 w-5 text-red-600 group-hover:text-red-300"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                  clip-rule="evenodd"
                />
              </svg>
            </span>
            Créer un compte
          </button>
        </div>
      </form>

      <div class="flex items-center justify-center space-x-2">
        <span class="h-px w-16 bg-gray-300"></span>
        <span class="text-gray-500 font-normal">Ou</span>
        <span class="h-px w-16 bg-gray-300"></span>
      </div>
      <div class="flex flex-row justify-center items-center space-x-3">
        <button
          v-on:click="GoogleLogin()"
          class="
            group
            relative
            w-full
            flex
            justify-center
            py-3
            px-4
            border border-transparent
            text-sm
            font-medium
            rounded-md
            bg-white
            shadow-sm
            hover:shadow-md
            focus:outline-none
            focus:ring-2 focus:ring-offset-2 focus:ring-red-400
          "
        >
          <span class="absolute left-0 inset-y-0 flex items-center px-2 w-1/3">
            <img src="../../assets/google.png" class="w-6 h-6"
          /></span>
          <p class="w-2/3">
          Connexion google
          </p>
        </button>

        <!-- <button
          v-on:click="fb_login(), (isLoading = true)"
          class="
            group
            relative
            w-full
            flex
            justify-center
            py-3
            px-4
            border border-transparent
            text-sm
            font-medium
            rounded-md
            text-white
            bg-blue-500
            hover:bg-blue-600
            focus:outline-none
            focus:ring-2 focus:ring-offset-2 focus:ring-red-400
          "
        >
          <span class="absolute left-0 inset-y-0 flex items-center px-2 w-1/2">
            <img
              src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHhtbG5zOnN2Z2pzPSJodHRwOi8vc3ZnanMuY29tL3N2Z2pzIiB3aWR0aD0iNTEyIiBoZWlnaHQ9IjUxMiIgeD0iMCIgeT0iMCIgdmlld0JveD0iMCAwIDI0IDI0IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA1MTIgNTEyIiB4bWw6c3BhY2U9InByZXNlcnZlIiBjbGFzcz0iIj48Zz48cGF0aCB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGQ9Im0xNS45OTcgMy45ODVoMi4xOTF2LTMuODE2Yy0uMzc4LS4wNTItMS42NzgtLjE2OS0zLjE5Mi0uMTY5LTMuMTU5IDAtNS4zMjMgMS45ODctNS4zMjMgNS42Mzl2My4zNjFoLTMuNDg2djQuMjY2aDMuNDg2djEwLjczNGg0LjI3NHYtMTAuNzMzaDMuMzQ1bC41MzEtNC4yNjZoLTMuODc3di0yLjkzOWMuMDAxLTEuMjMzLjMzMy0yLjA3NyAyLjA1MS0yLjA3N3oiIGZpbGw9IiNmZmZmZmYiIGRhdGEtb3JpZ2luYWw9IiMwMDAwMDAiIHN0eWxlPSIiIGNsYXNzPSIiPjwvcGF0aD48L2c+PC9zdmc+"
              class="w-6 h-6"
          /></span>
          <p class="w-9/12">
          Connexion facebook
          </p>
        </button> -->
      </div>
    </div>

    <!--<div
      :class="isLoading ? 'block' : 'hidden'"
      class="fulfilling-square-spinner"
    >
      <div class="spinner-inner"></div>
    </div>-->
  </div>
</template>

<script>
import store from "../../store.js";
import router from "../../router/index.js";
import helpers from "../../helpers.js";

export default {
  data() {
    return {
      form: {
        firstname: "",
        lastname: "",
        email: "",
        password: "",
        password_confirmation: "",
      },
      user: [],
      error: null,
      isLoading: false,
    };
  },

  computed: {
    isToken() {
      return this.$store.getters.get_token;
    },
  },

  methods: {
    register() {
      this.isLoading = true;
      this.form.webGLFingerprint = helpers.getWebGLFingerprint();

      this.$store
        .dispatch("registerAction", this.form)
        .then(() => {
          this.$root.$emit("Event", this.$store.getters.get_user);
          this.$router.push("/dashboard");
          this.isLoading = false;
        })
        .catch((error) => {
          this.error = error.response.data.errors;
          this.isLoading = false;
        });
    },
    async GoogleLogin() {
      try {
        const googleUser = await this.$gAuth.signIn();
        if (!googleUser) {
          return null;
        }

        this.user = googleUser.getBasicProfile();
        store.commit("set_image", googleUser.getBasicProfile().getImageUrl());

        this.isLoading = true;
        this.$store
          .dispatch("loginSocialAction", {
            email: this.user.getEmail(),
            first_name: this.user.getGivenName(),
            last_name: this.user.getFamilyName(),
          })
          .then(({ data }) => {
            this.isLoading = false;
            this.$root.$emit("Event", this.$store.getters.get_user);
            if(this.$store.getters.get_user.profil_image)
            {
              this.$store.commit("set_image", this.$store.getters.get_user.profil_image );
            }
            else{
              this.$store.commit("set_image",googleUser.getBasicProfile().getImageUrl());
            }
            this.$router.push("/dashboard");
          })
          .catch((error) => {
            this.isLoading = false;
            this.error =
              "les informations enregistrées ne correspondent pas à nos informations";
          });
      } catch (error) {
        console.error(error);
        return null;
      }
    },
    // async fb_login() {
    //   let vm = this;
    //   FB.login(
    //     function (response) {
    //       if (response.authResponse) {
    //         FB.api(
    //           "/me?fields=email,first_name,last_name,picture ",
    //           function (response) {
    //             vm.isLoading = true;
    //             vm.$store
    //               .dispatch("loginSocialAction", {
    //                 email: response.email,
    //                 first_name: response.first_name,
    //                 last_name: response.last_name,
    //               })
    //               .then(() => {
    //                 vm.isLoading = false;
    //                 vm.$router.push("/dashboard");

    //                 if(vm.$store.getters.get_user.profil_image)
    //                   {
    //                     vm.$store.commit("set_image", vm.$store.getters.get_user.profil_image );
    //                   }
    //                 else{
    //                     vm.$store.commit("set_image", response.picture.data.url);
    //                   }
                    
    //               })
    //               .catch(()=> {
    //                 vm.isLoading = false;
    //                 vm.errorm();
    //                 } );

    //           }
    //         );
    //       }
    //     },
    //     {
    //       scope: "public_profile,email",
    //     }
    //   );
    // },
  },
};
</script>

<style>
.fulfilling-square-spinner,
.fulfilling-square-spinner * {
  box-sizing: border-box;
}

.fulfilling-square-spinner {
  height: 100px;
  width: 100px;
  position: relative;
  border: 4px solid #f87171;
  animation: fulfilling-square-spinner-animation 4s infinite ease;
}

.fulfilling-square-spinner .spinner-inner {
  vertical-align: top;
  display: inline-block;
  background-color: #f87171;
  width: 100%;
  opacity: 1;
  animation: fulfilling-square-spinner-inner-animation 4s infinite ease-in;
}

@keyframes fulfilling-square-spinner-animation {
  0% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(180deg);
  }

  50% {
    transform: rotate(180deg);
  }

  75% {
    transform: rotate(360deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes fulfilling-square-spinner-inner-animation {
  0% {
    height: 0%;
  }

  25% {
    height: 0%;
  }

  50% {
    height: 100%;
  }

  75% {
    height: 100%;
  }

  100% {
    height: 0%;
  }
}
</style>